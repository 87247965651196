import './App.css';
import dots from './assets/imgs/dots.png'

function App() {
  return (
    <div className="App">
      <h2>danreid</h2>
      <img style={{ marginTop: '20px' }} src={dots} alt="dots"></img>
    </div>
  );
}

export default App;
